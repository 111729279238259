
import index_controller from "@/service/index_controller";
import Cookies from 'quasar/src/plugins/Cookies.js';;
import { RolesEnum } from "@/helpers/enums/RolesEnum";

export const  checkUserSessionGuard =  async(to,from,next) =>{
    if (!Cookies.has("user")) {
        next({ name: "login", query: { redirect: to.fullPath } });
        return false;
    }
    try{
        const response = await index_controller.validarToken();
        if(response.data.status == "error"){
            next({ name: "login", query: { redirect: to.fullPath } });
            return false;
        }
        const { user } = response.data;
        // Redirigir a SUPERADMIN si intenta acceder a una ruta no permitida
        if (
            user.esSuperAdmin &&
            !["superadmin", "ConfirmarCambioFecha"].includes(to.name)
        ) {
            next({ name: "superadmin" });
            return false;
        }
        if (to.meta.roles.includes(RolesEnum.SUPERADMIN) && user.esSuperAdmin) {
            next();
            return true;
        }
        if(to.meta.roles.includes(RolesEnum.ADMINISTRADOR) && user.esAdministrador){
            next();
            return true;
        }
        if(to.meta.roles.includes(RolesEnum.USUARIO)){
            next();
            return true;
        }
        next({name:'notFound'});
        return true;
    }catch(e){
        next({ name: "login", query: { redirect: to.fullPath } });
        return false;
    }
}