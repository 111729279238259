/* eslint-disable */
// eslint-disable-next-line
import axios from "axios";
import Cookies from 'quasar/src/plugins/Cookies.js';;
import { postRequest } from "@/helpers/fetch-wrapper";
const url =  process.env.VUE_APP_URL_SERVIDOR_PHP + "/Index_controller/";

export default {
  login(emile, password) {
    return postRequest(url+"login", {username:emile, password: btoa(password)});
  },

  validarToken() {
    return postRequest(url + "validarToken", {});
  },

  getPermisos() {
    return postRequest(url + "getPermisos", {});
  },
  getDatosCuenta() {
    return postRequest(url + "cuenta", {});
  },
  postClientes(body) {
    return postRequest(url+"nuevo_cliente",body);
  },
  postEquipo(body) {
    return postRequest(url+"nuevo_equipo",body);
  },

  editEquipo(body){
    return postRequest(url+"editar_equipo",body);
  },

  editCliente(body){
    return postRequest(url+"editar_cliente",body);
  },

  eliminarEquipo(equipoCodigo){
    const body = {
        EquiposCodigo: equipoCodigo
    }
    return postRequest(url+"eliminar_equipo",body);
  },
  

  newFoto(foto) {
    return postRequest(url+"newFoto",{foto});
  },

  async register(emile, password,celular,paisIsoCode,referidoId) {
    const  body = { username: emile, password: btoa(password), celular, paisIsoCode ,referidoId};
    return postRequest(url+"registrar",body);
  },

  async resetPasswordRequest(email){
    return postRequest(url+"restorePasswordRequest",{email});
  },

  async restorePassword(token, newPassword){
    return postRequest(url+"updatePassword",{newPassword: btoa(newPassword), token});
  },

  getPlanes(){
    return postRequest(url+"getPlanes", {});
  },

  getPlanesUpgrade(){
    return postRequest(url+"getPlanesUpgrade",{});
  },

  generateRandomNroSerie(){ //generacion de nro de serie random(no repetido para la cuenta).
    return postRequest(url+"generarNumeroDeSerieAleatorio",{});
  },

  getDatosCuenta(filtro) {
    return postRequest(url + "buscarCuenta", {filtro});
  },

  actualizarVencimiento(cuentaId, nuevaFecha, detalles) {
    return postRequest(url + "actualizarVencimiento", { cuentaId, nuevaFecha, detalles });
  },
  enviarMailCambioFecha(cuentaCodigo, cuentaNombre, fechaNueva, detalles) {
    return postRequest(url + "enviarMailCambioFecha", {
      cuentaCodigo,
      cuentaNombre,
      fechaNueva,
      detalles,
    });
  }
  
  }
